import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({providedIn: 'root'})
export class HeaderService {

    private title: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public getTitle(): string {
        return this.title.getValue();
    }

    public getTitleAsObservable(): Observable<string> {
        return this.title.asObservable();
    }

    public setTitle(title: string) {
        this.title.next(title);
    }
}